<template>
  <div class="permission-view">
    <!-- 功能栏 -->
    <div class="action-bar">
      <!-- 新增按钮 -->
      <a-button type="primary" @click="handleNew">+ 新增权限</a-button>
    </div>
    <!-- 列表 -->
    <a-table
      class="content-list"
      :columns="columns"
      :dataSource="dataSource"
      :rowKey="record => record.id"
      :pagination="pagination"
      :loading="isLoading"
      @change="handleTableChange">
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 操作 -->
        <template v-if="column.key === 'operate'">
          <a class="operation-item" @click="handleEdit(record)">编辑</a>
          <!-- 删除 -->
          <a-popconfirm
            title="确定要删除吗？"
            @confirm="handleDelete(record)"
          >
            <a class="operation-item">删除</a>
          </a-popconfirm>
        </template>
      </template>
    </a-table>
    <!-- 新增 -->
    <permission-new ref="permission-new" @reload="permissionList"></permission-new>
  </div>
</template>

<script>
import PermissionNew from './PermissionNew'
import { permissionList, permissionDelete } from '@/api/request'
// 数据源
const columns = [
  {
    title: '权限名称',
    dataIndex: 'show_name',
    key: 'show_name'
  },
  {
    title: '权限标识',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '路由',
    dataIndex: 'route',
    key: 'route'
  },
  {
    title: '父级',
    dataIndex: 'parent_name',
    key: 'parent_name'
  },
  {
    title: '操作',
    dataIndex: 'operate',
    key: 'operate'
  }
]
export default {
  props: {
    source: {
      type: String,
      default: ''
    }
  },
  components: {
    PermissionNew
  },
  data () {
    return {
      columns,
      dataSource: [],
      isLoading: false,
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1
      }
    }
  },
  methods: {
    // 分页
    handleTableChange (pagination, filters, sorter) {
      this.pagination.current = pagination.current
      this.permissionList()
    },
    // 新增
    handleNew () {
      this.$refs['permission-new'].show(this.source)
    },
    // 编辑
    handleEdit (record) {
      this.$refs['permission-new'].show(this.source, record)
    },
    // 删除
    handleDelete (record) {
      this.permissionDelete(record)
      // const that = this
      // this.$confirm({
      //   title: '确定删除？',
      //   content: '删除之后将无法恢复！',
      //   okText: '确定',
      //   okType: 'danger',
      //   cancelText: '取消',
      //   onOk () {
      //     that.permissionDelete(record)
      //   }
      // })
    },
    // 权限列表
    permissionList () {
      this.isLoading = true
      const params = {
        source: this.source,
        page: this.pagination.current,
        page_size: this.pagination.pageSize
      }
      permissionList(params).then(res => {
        this.isLoading = false
        const { code, data, msg } = res
        if (code === 0) {
          this.dataSource = data.data
          this.pagination.total = data.total
        } else {
          this.$message.error(msg)
        }
      }).catch(err => {
        this.isLoading = false
        this.$message.error(err)
      })
    },
    // 权限删除
    permissionDelete (data) {
      this.isLoading = true
      const params = {
        id: data.id
      }
      permissionDelete(params).then(res => {
        this.isLoading = false
        const { code, msg } = res
        if (code === 0) {
          this.permissionList()
          this.$message.success('删除成功')
        } else {
          this.$message.error(msg)
        }
      }).catch(err => {
        this.isLoading = false
        this.$message.error(err)
      })
    }
  },
  mounted () {
    this.permissionList()
  }
}
</script>

<style scoped>
.permission-view {
  position: relative;
  background-color: #fff;
  height: 100%;
}
.action-bar {
  width: 100%;
  padding: 0 25px 0 25px;
  margin: 15px 0 15px 0;
  text-align: right;
}
.content-list {
  padding: 0px 25px 0px 25px;
}

</style>
